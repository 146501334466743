import React from 'react';

import { CLEAR_YEAR_FILTER } from './brotherGrid';
import './brother.scss';

const YearSelect = ({ yearList, yearFilter, setYearFilter }) => {
  if (!yearList) {
    return null;
  }

  yearList.sort().reverse();

  return (
    <>
      {yearList.map(year => (
        <button
          style={yearFilter === year ? { backgroundColor: '#dba100' } : {}}
          id={year}
          key={year}
          onClick={() => {
            console.log(`Setting year filter to ${yearFilter === year ? CLEAR_YEAR_FILTER : year}`);
            setYearFilter(yearFilter === year ? CLEAR_YEAR_FILTER : year);
          }}
        >
          {year}
        </button>
      ))}
      {/* Add "Older" option */}
      <button
        style={yearFilter === 'Older' ? { backgroundColor: '#dba100' } : {}}
        id="older"
        key="older"
        onClick={() => {
          console.log(`Setting year filter to ${yearFilter === 'Older' ? CLEAR_YEAR_FILTER : 'Older'}`);
          setYearFilter(yearFilter === 'Older' ? CLEAR_YEAR_FILTER : 'Older');
        }}
      >
        Older
      </button>
    </>
  );
};

export default YearSelect;